import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
    gallery: []
};

const slice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setGallery(state, action) {
        const { gallery } = action.payload;
        state.gallery = gallery;
    },
    addGallery(state, action) {
      const { gallery_item } = action.payload;
      state.gallery.push(gallery_item);
    },
    updateGallery(state, action) {
      const { gallery_item } = action.payload;
      const index = findIndex(state.gallery, { id: gallery_item.id });

      if (index !== -1) {
        state.gallery[index] = gallery_item;  
      }
      
    },
    deleteGallery(state, action) {
      const { gallery_item } = action.payload;
      const index = findIndex(state.gallery, { id: gallery_item.id });

      if (index !== -1) {
        state.gallery.splice(index, 1);
      }
      
    },
    clearGallery(state) {
      state.gallery = initialState.gallery;
      state.actionType = initialState.actionType;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setGallery,
    addGallery,
    updateGallery,
    deleteGallery,
    clearGallery,
} = slice.actions;