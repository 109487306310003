import { Helmet } from 'react-helmet-async';
import { LandingListView } from 'src/sections/landing/view';

// ----------------------------------------------------------------------

export default function LandingListPage() {


  return (
    <>
      <Helmet>
        <title> SalesRushAI | Landing List</title>
      </Helmet>

      <LandingListView />
    </>
  );
}
