import { CallView } from "./call-log/view";

const CallLog = () => {
    console.log('test');
    return (
        <CallView />
    )
}

export default CallLog;
