import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
    posts: []
};

const slice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts(state, action) {
        const { posts } = action.payload;
        state.posts = posts;
    },
    addPost(state, action) {
      const { post } = action.payload;
      state.posts.push(post);
    },
    updatePost(state, action) {
      const { post } = action.payload;
      const index = findIndex(state.posts, { id: post.id });

      if (index !== -1) {
        state.posts[index] = post;  
      }
      
    },
    deletePost(state, action) {
      const { post } = action.payload;
      const index = findIndex(state.posts, { id: post.id });

      if (index !== -1) {
        state.posts.splice(index, 1);
      }
      
    },
    clearPost(state) {
      state.posts = initialState.posts
      state.actionType = initialState.actionType;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setPosts,
    addPost,
    updatePost,
    deletePost,
    clearPost,
} = slice.actions;
