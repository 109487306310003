/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';

// Mui
import {
  Stack,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';

// Iconify
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function FieldTableToolbar({
  filters,
  onFilters,
  resetFilters,
  handleResetFilters,
  //
  createdOptions,
  objectOptions,
  ...other
}) {

  const [created, setCreated] = useState(filters.created);
  const [status, setStatus] = useState('');


  const handleChangeUserLevel = useCallback((event) => {
    const {
      target: { value },
    } = event;
    onFilters('created', value)
    setCreated(value)
  }, [onFilters]);


  const handleChangeStatus = useCallback((event) => {
    const {
      target: { value },
    } = event;
    onFilters('status', value);
    setStatus(value)
  }, [onFilters]);

  const handleChangeLabel = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      onFilters('label', value);

    },
    [onFilters]
  );

  const resetSelectedStatus = useCallback(() => {
    setStatus(status);
  }, [status]);

  const clearFilterLabel = useCallback(() => {
    onFilters('label', '');
  }, [onFilters]);

  useEffect(() => {



    if (resetFilters) {
      if (filters.created === '') {
        setCreated('');
      }

      if (filters.status === '') {
        setStatus('');
      } else {
        resetSelectedStatus();
      }
      handleResetFilters();
    }

  }, [resetFilters, filters, handleResetFilters, resetSelectedStatus]);

  useEffect(() => {
    onFilters('status', 'active');
    setStatus('active')
  }, [])

  return (
    <Stack direction="row" spacing={1} {...other}>

      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}>
        <InputLabel id="required">Status</InputLabel>
        <Select
          name="status"
          label="Status"
          value={status}
          onChange={handleChangeStatus}
          fullWidth
        >
          <MenuItem value='active'>Active</MenuItem>
          <MenuItem value='inactive'>Inactive</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        <InputLabel>Created by</InputLabel>
        <Select
          value={created}
          onChange={handleChangeUserLevel}
          input={<OutlinedInput label="User Level" />}
          sx={{ textTransform: 'capitalize' }}
        >
          {createdOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        <InputLabel>Object</InputLabel>
        <Select
          value={object}
          onChange={handleChangeObject}
          input={<OutlinedInput label="Object" />}
          sx={{ textTransform: 'capitalize' }}
        >
          <MenuItem value='all'>All</MenuItem>
          {objectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}



      <Stack direction="row" spacing={1}>
        <TextField
          fullWidth
          value={filters.label}
          onChange={handleChangeLabel}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton size="small" onClick={clearFilterLabel} sx={{ height: '1.7em' }}>
                <Iconify icon="mingcute:close-line" />
              </IconButton>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
}

FieldTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  resetFilters: PropTypes.bool,
  handleResetFilters: PropTypes.func,
  createdOptions: PropTypes.array,
  objectOptions: PropTypes.array,
};
