import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// components
import { Button, CircularProgress, Fab, IconButton, MenuItem, Modal, Paper, Popover, Stack, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { fphoneNumber } from 'src/utils/format-phonenumber';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// firebase
import { DATABASE } from 'src/firebase';
import { ref, onValue } from "firebase/database";
import { AnimatePresence, m } from 'framer-motion';
import PhoneNumberKeypad from './phone-number-keypad';
import PhoneNumberActions from './phone-number-actions';
// ----------------------------------------------------------------------

const ZINDEX = 98;

const POSITION = 24;

export default function PhoneNumberCalling({
    phoneNumber,
    callEnd,
    recording,
    mute,
    hold,
    isHold,
    isMute,
    onChangeAudio,
    isRecording,
    isCallActive,
    direction,
    addParticipant,
    Participants,
    removeParticipant,
    conferenceSid,
    mutedParticipant,
    endConference,
    setParticipants
}) {

    const theme = useTheme();
    const [isOpen, setOpen] = useState(null);
    const [audioOutputDevices, setAudioOutputDevices] = useState([]);
    const [seconds, setSeconds] = useState(0);
    const [isAddParticipant, setIsAddParticipant] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const onCall = useBoolean(false);
    const [loading, setLoading] = useState(false);

    // Escucha cambios en la ruta 'twilioRealTimeEvents/'
    useEffect(() => {
        const callsRef = ref(DATABASE, `Active_Twilio_Conferences/${conferenceSid}/`);
        if (direction === 'inbound') onCall.onTrue();
        const unsubscribe = onValue(callsRef, (snapshot) => {
            const data = snapshot.val();

            if (data && data[isCallActive.sid]) {
                onCall.onTrue();
            }
        });
        // Limpia la suscripción cuando el componente se desmonte
        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const participantsRef = ref(DATABASE, `Active_Twilio_Conferences/${conferenceSid}/`);

        const unsubscribe = onValue(participantsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setParticipants(prevParticipants =>
                    prevParticipants.map(participant =>
                        data[participant.sid] // Si el sid del objeto en Firebase coincide
                            ? { ...participant, inCall: true } // Actualiza el campo inCall a true
                            : participant // Si no coincide, deja el objeto igual
                    )
                );
            }
        });

        // Limpieza del listener cuando se desmonta el componente
        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);

    // Efecto para manejar el contador
    useEffect(() => {
        let timer;
        if (onCall.value) {
            timer = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, [onCall]);



    const formatTime = () => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };
    const handleClose = useCallback((deviceId) => {
        onChangeAudio(deviceId)
        setOpen(null);
    }, [onChangeAudio]);

    const enumerateAudioOutputDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const isAudioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
            setAudioOutputDevices(isAudioOutputDevices);
            setOpen(true);
        } catch (error) {
            console.error('Failed to enumerate audio devices:', error);
        }
    };

    const handleAddParticipant = async () => {
        setIsAddParticipant(prev => !prev);
    }

    const handleMakeCall = () => {
        Participants.push({ sid: '', phone: newPhoneNumber, muted: false, inCall: false })
        setParticipants(Participants);
        addParticipant(newPhoneNumber);
        setIsAddParticipant(prev => !prev);
    }

    const handleClickButton = (number) => {
        setNewPhoneNumber((prev) => prev + number);
    }

    const handleDelete = () => {
        setNewPhoneNumber((prev) => prev.slice(0, -1));
    };

    const handleRemoveParticipant = (event) => {
        const updatedParticipants = Participants.map((participant) => {
            if (participant.sid === event.sid) {
                return { ...participant, loadingRemove: true };
            }
            return participant;
        });
        // Actualiza el estado con el nuevo array
        setParticipants(updatedParticipants);

        removeParticipant(event.sid).finally(() => {
            const removeUpdatedParticipants = Participants.map((participant) => {
                if (participant.sid === event.sid) {
                    return { ...participant, loadingRemove: false };
                }
                return participant;
            });
            setParticipants(removeUpdatedParticipants);
        })
    }

    const handleMutedParticipant = (event) => {
        const updatedParticipants = Participants.map((participant) => {
            if (participant.sid === event.sid) {
                return { ...participant, loadingMute: true };
            }
            return participant;
        });
        // Actualiza el estado con el nuevo array
        setParticipants(updatedParticipants);
        mutedParticipant(event.sid, !event.muted)
    }

    const handleEndConference = () => {
        setLoading(true);
        endConference().finally(() => {
            setLoading(false); // Detener el spinner cuando la llamada se complete o falle
        });
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: theme.spacing(3),

            }}>
                {Participants.length < 2 ? (
                    <>
                        <Typography color="primary" variant="body2" sx={{ marginBottom: theme.spacing(1) }}>
                            {onCall.value ? "On Call" : "Calling..."}
                        </Typography>

                        {onCall.value && (
                            <Typography sx={{ fontSize: '1.5rem', marginBottom: theme.spacing(0) }}>
                                {formatTime()}
                            </Typography>
                        )}

                        <Typography sx={{ fontSize: '1.5rem', marginBottom: theme.spacing(0) }}>
                            {fphoneNumber(phoneNumber)}
                        </Typography>

                        {/* <Typography variant="subtitle2" sx={{ color: '#4ba4c0', marginBottom: { xs: theme.spacing(40), sm: theme.spacing(20) } }}>
                            Save To CRM
                        </Typography> */}
                    </>
                ) : (
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)', // Tres columnas
                            gap: 1, // Espacio entre los elementos
                            width: '100%',
                            height: "300px",
                            maxHeight: "300px",
                            overflowY: 'auto',

                        }}
                    >
                        {Participants.map((participant, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: 1,
                                }}
                            >
                                {/* Botones de control (mute y colgar) */}
                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                                    <Fab
                                        size='small'
                                        color="grey.200"
                                        onClick={() => handleMutedParticipant(participant)}
                                        disabled={participant.loadingMute}
                                    >
                                        {participant.loadingMute ? (
                                            <CircularProgress size={15} color="inherit" />
                                        ) : (
                                            <Iconify icon={participant.muted ? 'ic:baseline-volume-off' : 'ic:baseline-volume-up'} sx={{ width: 15, height: 15 }} />
                                        )}
                                    </Fab>
                                    <Fab size='small' color="error" onClick={() => handleRemoveParticipant(participant)} disabled={participant.loadingRemove} >
                                        {participant.loadingRemove ? (
                                            <CircularProgress size={15} color="inherit" />
                                        ) : (
                                            <Iconify icon="ic:baseline-call-end" sx={{ width: 15, height: 15 }} />
                                        )}
                                    </Fab>
                                </Box>
                                {/* Nombre y tiempo */}
                                <Typography variant="caption">{participant.phone}</Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {!participant.inCall ? "Calling..." : "On call"}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                )}
                {/* <Box width="100%" sx={{ px: 2, mb: 2, maxHeight: 100, overflowY: 'scroll', marginBottom: theme.spacing(2) }}>
                    <TextField
                        value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Quisque ornare ex velit, cursus placerat nibh commodo et. 
                Proin eu facilisis augue. Praesent purus sapien, aliquet 
                sit amet porttitor non, iaculis."
                        multiline variant='filled'
                        fullWidth label="Notes" />
                </Box> */}
                {Participants.length < 2 ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end', // Alinear al final verticalmente
                            alignItems: 'center', // Alinear horizontalmente al centro
                            height: '100%', // Asegurarse de que ocupe toda la altura del contenedor padre
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                                gap: theme.spacing(3),
                                marginBottom: theme.spacing(1.5),
                                width: '100%', // Asegurarse de que ocupe el 100% del ancho
                            }}
                        >
                            {/* Tu contenido aquí (cada Stack con Fab y Typography) */}
                            <Stack>
                                <Stack>
                                    <Fab size='medium' color='gray.300' onClick={enumerateAudioOutputDevices}>
                                        <Iconify icon="iconoir:sound-high-solid" width={20} height={20} />
                                    </Fab>
                                    <Popover
                                        sx={{ top: -90 }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        id="simple-menu"
                                        anchorEl={isOpen}
                                        onClose={handleClose}
                                        open={Boolean(isOpen)}
                                    >
                                        {audioOutputDevices.map((device) => (
                                            <MenuItem key={device.deviceId} onClick={() => handleClose(device.deviceId)}>
                                                {device.label || `Device ${device.deviceId}`}
                                            </MenuItem>
                                        ))}
                                    </Popover>
                                </Stack>
                                <Typography variant='caption'>Audio</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' disabled={isHold} color={isMute ? 'default' : 'default'} onClick={mute}>
                                    <Iconify icon="mdi:mute" color={isMute ? "red" : 'default'} width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Mute</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' color='gray.300' onClick={handleAddParticipant}>
                                    <Iconify icon="mdi:user-add" width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Add</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' color={isHold ? 'default' : 'default'} onClick={hold}>
                                    <Iconify color={isHold ? "red" : 'default'} icon="material-symbols:pause" width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Hold</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' disabled={isHold} color='gray.300' onClick={recording}>
                                    <Iconify icon="ph:record-fill" color={isRecording && !isHold ? 'red' : 'default'} width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Record</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' color='gray.300' onClick={callEnd}>
                                    <Iconify icon="mingcute:transfer-fill" width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Transfer</Typography>
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginBottom: theme.spacing(1.5),
                            }}
                        >
                            <Fab size='medium' onClick={callEnd} color="error" sx={{ mb: 0, mt: 1 }}>
                                <Iconify icon="solar:phone-bold" />
                            </Fab>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end', // Alinear al final verticalmente
                            alignItems: 'center', // Alinear horizontalmente al centro,
                            marginTop: 5,
                            height: '100%', // Asegurarse de que ocupe toda la altura del contenedor padre
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                gap: theme.spacing(3),
                                marginBottom: theme.spacing(1.5),
                                width: '100%', // Asegurarse de que ocupe el 100% del ancho
                            }}
                        >
                            {/* Tu contenido aquí (cada Stack con Fab y Typography) */}
                            <Stack>
                                <Stack>
                                    <Fab size='medium' color='gray.300' onClick={enumerateAudioOutputDevices}>
                                        <Iconify icon="iconoir:sound-high-solid" width={20} height={20} />
                                    </Fab>
                                    <Popover
                                        sx={{ top: -90 }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        id="simple-menu"
                                        anchorEl={isOpen}
                                        onClose={handleClose}
                                        open={Boolean(isOpen)}
                                    >
                                        {audioOutputDevices.map((device) => (
                                            <MenuItem key={device.deviceId} onClick={() => handleClose(device.deviceId)}>
                                                {device.label || `Device ${device.deviceId}`}
                                            </MenuItem>
                                        ))}
                                    </Popover>
                                </Stack>
                                <Typography variant='caption'>Audio</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' disabled={isHold} color={isMute ? 'default' : 'default'} onClick={mute}>
                                    <Iconify icon="mdi:mute" color={isMute ? "red" : 'default'} width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Mute</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' color='gray.300' onClick={handleAddParticipant}>
                                    <Iconify icon="mdi:user-add" width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Add</Typography>
                            </Stack>
                            <Stack direction='column' alignItems='center' justifyContent='center'>
                                <Fab size='medium' disabled={isHold} color='gray.300' onClick={recording}>
                                    <Iconify icon="ph:record-fill" color={isRecording && !isHold ? 'red' : 'default'} width={20} height={20} />
                                </Fab>
                                <Typography variant='caption'>Record</Typography>
                            </Stack>
                        </Box>
                        {/* <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginBottom: theme.spacing(1.5),
                            }}
                        >
                            <Fab size='medium' onClick={callEnd} color="error" sx={{ mb: 0, mt: 1, width: 280, height: 40}}>
                                <Iconify icon="solar:phone-bold" />
                            </Fab>
                        </Box> */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 4,
                                width: '100%'
                            }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                size="large"
                                onClick={handleEndConference}
                                sx={{
                                    width: '80%',
                                    borderRadius: '25px', // Borde redondeado en las orillas
                                }}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress size={24} color='inherit' />)
                                    :
                                    (
                                        <>
                                            <Iconify icon="ic:baseline-call-end" sx={{ m: 1 }} />
                                            End Conference
                                        </>
                                    )
                                }
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
            <Modal
                open={isAddParticipant} // Controla si el modal está abierto o cerrado
                onClose={handleAddParticipant} // Acción para cerrar el modal
                aria-labelledby="custom-modal"
                aria-describedby="custom-modal-description"
                BackdropProps={{
                    style: { backgroundColor: 'transparent' } // Desactiva el oscurecimiento de fondo
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    sx={{
                        right: 0,
                        bottom: 0,
                        borderRadius: 2,
                        width: { xs: '100%', sm: 350 },
                        height: { xs: '100%', sm: 600 },
                        display: 'flex',
                        position: 'fixed',
                        zIndex: ZINDEX + 1,
                        m: `${POSITION}px`,
                        overflow: 'hidden',
                        flexDirection: 'column',
                        // boxShadow: (theme) => theme.customShadows.dropdown,
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="end"
                        sx={{
                            bgcolor: 'background.neutral',
                            p: (themes) => theme.spacing(0.5),
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={handleAddParticipant}>
                            <Iconify icon="mingcute:close-line" />
                        </IconButton>
                    </Stack>
                    <AnimatePresence>
                        <m.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ ease: "easeOut", duration: 0.4 }} >
                            <PhoneNumberKeypad
                                phoneNumber={newPhoneNumber}
                                handleClickButton={handleClickButton}
                                handleDelete={handleDelete}
                                callTo={handleMakeCall}
                            />
                            <PhoneNumberActions />
                        </m.div>
                    </AnimatePresence>
                </Paper>
            </Modal>
        </>
    );
}

PhoneNumberCalling.propTypes = {
    phoneNumber: PropTypes.string,
    callEnd: PropTypes.func,
    mute: PropTypes.func,
    hold: PropTypes.func,
    isHold: PropTypes.bool,
    isMute: PropTypes.bool,
    isRecording: PropTypes.bool,
    onChangeAudio: PropTypes.func,
    recording: PropTypes.func,
    isCallActive: PropTypes.string,
    direction: PropTypes.string,
    addParticipant: PropTypes.func,
    Participants: PropTypes.array,
    removeParticipant: PropTypes.func,
    conferenceSid: PropTypes.string,
    mutedParticipant: PropTypes.func,
    endConference: PropTypes.func,
    setParticipants: PropTypes.func
};