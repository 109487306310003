/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
//
import Image from '../image';

import backVideo from '../../assets/general/like-video.png'

// ----------------------------------------------------------------------

export default function SingleFilePreview({ imgUrl = '', extention }) {

  const styleVideo = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }

  const styleImage = {
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    position: 'absolute',
  }

  const defineExtention = ['mp4', 'mov', 'qt'].includes(extention) || ['.mp4', '.mov', '.qt'].includes(imgUrl) ? styleVideo : styleImage

  return (
    <Box
      sx={{
        p: 1,
        ...defineExtention
      }}
    >
      <Image
        alt="file preview"
        src={['mp4', 'mov', 'qt'].includes(extention) ? backVideo : ['.mp4', '.mov', '.qt'].includes(imgUrl) ? backVideo : imgUrl}
        sx={{
          width: 1,
          height: 1,
          borderRadius: 1,
          // objectFit: ['mp4', 'mov'].includes(extention) ? 'contain' : 'cover'
          objectFit: 'contain'
        }}
      />
    </Box>
  );
}

SingleFilePreview.propTypes = {
  imgUrl: PropTypes.string,
  extention: PropTypes.string,
};
