import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  areaCode: undefined,
  phoneNumber:  null,
  friendlyName: null,
  price: null,
  tab: 'active numbers',
};

const slice = createSlice({
  name: 'twilioavailablephonenumber',
  initialState,
  reducers: {
    setAreaCode(state, action) {
        const { areaCode } = action.payload;
        state.areaCode = areaCode;
    },
    setPhoneNumberBuy(state, action) {
      const { phoneNumber, price, friendlyName } = action.payload;
      state.phoneNumber = phoneNumber;
      state.friendlyName = friendlyName;
      state.price = price;
    },
    setTab(state, action) {
      const { tab } = action.payload;
      state.tab = tab;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setAreaCode,
  setPhoneNumberBuy,
  setTab,
} = slice.actions;
