/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { Chip, Button } from '@mui/material'

import Iconify from 'src/components/iconify'


// ----------------------------------------------------------------------

export default function LandingTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}) {

  console.log(filters)

  const handleRemoveUserLevel = useCallback(() => {
    onFilters('created', '')
  }, [onFilters])

  const handleRemoveStatus = useCallback(() => {
    onFilters('object', '')
  }, [onFilters])

  const handleRemoveLabel = useCallback(() => {
    onFilters('label', '')
  }, [onFilters])

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          results found
        </Box>
      </Box>

      <Stack
        flexGrow={1}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >


        {filters.created !== '' && (
          <Block label="Created:">
            <Chip
              label={filters.created}
              size="small"
              onDelete={() => handleRemoveUserLevel()}
            />
          </Block>
        )}


        {filters.status !== '' && (
          <Block label="Status:">
            <Chip
              label={filters.status}
              size="small"
              onDelete={() => handleRemoveStatus()}
            />
          </Block>
        )}

        {filters.label !== '' && (
          <Block label="Search:">
            <Chip
              label={filters.label}
              size="small"
              onDelete={() => handleRemoveLabel()}
            />
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  )
}

LandingTableFiltersResult.propTypes = {
  filters: PropTypes.any,
  status: PropTypes.string,
  onFilters: PropTypes.func,
  results: PropTypes.number,
  onResetFilters: PropTypes.func,
}

// ----------------------------------------------------------------------

function Block({ label, children, sx, ...other }) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  )
}

Block.propTypes = {
  filters: PropTypes.any,
  children: PropTypes.node,
  label: PropTypes.string,
  sx: PropTypes.object,
}
