import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BRIDGE_DATA_OUT_PUT } from 'src/config-global';

const bridgeDataOutPutBaseQuery = createApi({
    reducerPath: 'bridgedataoutput',
    baseQuery: fetchBaseQuery({
        baseUrl: BRIDGE_DATA_OUT_PUT.api_url,
    }),
    endpoints: () => ({}),
});

export default bridgeDataOutPutBaseQuery;
