import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function ContactCenterAlert({ title, LinkTo, isOpen, onClose}) {

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent sx={{ color: 'text.secondary' }}>
            <Typography>
              To Learn More About This Error Message <br/> And Possible Solutions Click  
              <a href={LinkTo} rel="noreferrer" target='_blank'> Here</a>
            </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ContactCenterAlert.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  LinkTo: PropTypes.string,
};
