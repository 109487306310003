// routes
import { paths } from 'src/routes/paths';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  },
  {
    title: 'Buy',
    path: '/form/buy-a-property',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
  },
  {
    title: 'Sell',
    path: '/form/sell-a-property',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
  },
  {
    title: 'Listings',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
    path: paths.listings.homes,
  },
  {
    title: 'Learn',
    path: '/post',
    icon: <Iconify icon="solar:atom-bold-duotone" />,
  },
];
