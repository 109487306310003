import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// ----------------------------------------------------------------------

export default function RHFAutocomplete({
  name,
  label,
  inputVariant = 'outlined',
  placeholder,
  helperText,
  multiple = false,
  ...other
}) {
  const { control, setValue } = useFormContext();
  const [inputHeight, setInputHeight] = useState('44');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple={multiple}
          chip="true"
          clearable="true"
          isOptionEqualToValue={(option, value) => option === value}
          onOpen={() => {
            const element = document.getElementById(name);
            if (element) {
              setInputHeight(element.clientHeight);
            }
          }}
          onClose={() => {
            setInputHeight('44');
          }}
          includeInputInList
          renderInput={(params) => (
            <TextField
              variant={inputVariant}
              label={label}
              placeholder={placeholder}
              error={!!error}
              size="large"
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
  );
}

RHFAutocomplete.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
};

export function RHFAutocompleteTags({ name, label, placeholder, helperText, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={(params) => (
            <TextField
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
  );
}

RHFAutocompleteTags.propTypes = {
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,  
};


export function RHFAutocompleteAction({ name, label, helperText, placeholder, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (

        <Autocomplete
          {...field}
          id={`autocomplete-${name}`}
          name={name}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error?.message : helperText}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
          {...other}
        />

      )}
    />
  );
}

RHFAutocompleteAction.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
};
