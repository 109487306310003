import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// framer motion
import { AnimatePresence, m } from 'framer-motion';
// components
import Iconify from 'src/components/iconify';
import { Fab, IconButton, useTheme } from '@mui/material';
import { useEffect } from 'react';

const PhoneNumberKeypad = ({
    phoneNumber,
    handleClickButton,
    handleDelete,
    callTo
}) => {

    const theme = useTheme();
    const alphabet = {
        2: 'ABC',
        3: 'DEF',
        4: 'GHI',
        5: 'JKL',
        6: 'MNO',
        7: 'PQRS',
        8: 'TUV',
        9: 'WXYZ',
    }

    const sounds = {
        0: '/sounds/dtmf-0.mp3',
        1: '/sounds/dtmf-1.mp3',
        2: '/sounds/dtmf-2.mp3',
        3: '/sounds/dtmf-3.mp3',
        4: '/sounds/dtmf-4.mp3',
        5: '/sounds/dtmf-5.mp3',
        6: '/sounds/dtmf-6.mp3',
        7: '/sounds/dtmf-7.mp3',
        8: '/sounds/dtmf-8.mp3',
        9: '/sounds/dtmf-9.mp3',
        "#": '/sounds/dtmf-hash.mp3',
        "*": '/sounds/dtmf-star.mp3',
    };

    const playSound = (key) => {
        console.log(sounds[key])
        const audio = new Audio(sounds[key]);
        audio.play();
    };

    const handleKeyDown = (e) => {
        const { key } = e;
        console.log('key', key);
        if (key === 'Backspace') {
            handleDelete();
        } else if (key === 'Enter') {
            callTo();
        } else if (key >= 0 && key <= 9) {
            handleClickButton(key);
            playSound(key)
        }
    }

    const handleMouseClickBottom = (key) => {
        handleClickButton(key);
        playSound(key)
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    });

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: { xs: theme.spacing(3), sm: theme.spacing(4) }
        }}>
            <Typography sx={{
                fontSize: { xs: '2rem', sm: '1.5rem' },
                marginBottom: { xs: theme.spacing(phoneNumber ? 12 : 18), sm: theme.spacing(phoneNumber ? 10.5 : 15) },
            }}>
                {phoneNumber}
            </Typography>
            {/* IMASK Typography */}
            {/* <Typography
            sx={{
                fontSize: '1.5rem',
                marginBottom: (theme) => theme.spacing(phoneNumber ? 2 : 6.5),
                display: 'flex', // Asegura que el contenido se alinee correctamente
                alignItems: 'center' // Alinea verticalmente el contenido si es necesario
            }}
        >
            <IMaskInput
                mask="+1 (000) 000-0000"
                value={phoneNumber}
                readOnly
                unmask={false}
                style={{
                    textAlign: 'center',
                    fontSize: 'inherit', // Hereda el tamaño de fuente del Typography
                    border: 'none', // Opcional: elimina el borde si no lo necesitas
                    backgroundColor: 'transparent' // Opcional: ajusta el color de fondo
                }}
            />
        </Typography> */}
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: { xs: theme.spacing(2), sm: theme.spacing(1.5) },
                marginBottom: { xs: theme.spacing(2.5), sm: theme.spacing(1.5) },
            }}>
                {[...Array(9).keys()].map((i) => (

                    <Fab size='large' onKeyDown={handleKeyDown} onClick={() => handleMouseClickBottom(i + 1)} key={i + 1} color='gray.300'
                        sx={{
                            width: { xs: '70px', sm: '56px' },
                            height: { xs: '70px', sm: '56px' },
                            fontSize: { xs: '1.5rem', sm: '1rem' }
                        }}
                    >

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '15%'
                        }}>

                            <Typography sx={{ fontSize: { xs: 25, sm: 15 } }}>{i + 1}</Typography>
                            <Typography sx={{ fontSize: { xs: 11, sm: 10 } }} color="grey.600" >{alphabet[i + 1]}</Typography>
                        </div>
                    </Fab>
                ))}
                <Fab size='large' color='gray.300'
                    sx={{
                        width: { xs: '70px', sm: '56px' },
                        height: { xs: '70px', sm: '56px' },
                        fontSize: { xs: 25, sm: 15 }
                    }} >
                    *
                </Fab>
                <Fab size='large' color='grey.600' onKeyDown={handleKeyDown} onClick={() => handleMouseClickBottom(0)}
                    sx={{
                        width: { xs: '70px', sm: '56px' },
                        height: { xs: '70px', sm: '56px' },
                        fontSize: { xs: '1.5rem', sm: '1rem' }
                    }}
                >
                    <Typography sx={{ fontSize: { xs: 25, sm: 15 } }}>0</Typography>
                </Fab>
                <Fab size='large' color='gray.300'
                    sx={{
                        width: { xs: '70px', sm: '56px' },
                        height: { xs: '70px', sm: '56px' },
                        fontSize: { xs: 25, sm: 15 }
                    }}
                >
                    #
                </Fab>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: theme.spacing(0),
            }}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                }}>
                    <Box />
                    <Fab size='large' onClick={callTo} color="primary" sx={{
                        mb: { xs: 4, sm: 2 }, mt: 1, width: { xs: '70px', sm: '56px' },
                        height: { xs: '70px', sm: '56px' },
                        fontSize: { xs: 25, sm: 15 }
                    }}>
                        <Iconify icon="solar:phone-bold" />
                    </Fab>
                    <AnimatePresence>
                        {
                            phoneNumber &&
                            <m.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ duration: 0.1 }}
                            >
                                <IconButton onClick={handleDelete} sx={{
                                    mb: 0, mt: 1, width: { xs: '80px', sm: '56px' },
                                    height: { xs: '80px', sm: '56px' },
                                    fontSize: { xs: 25, sm: 15 }
                                }}>
                                    <Iconify icon="fa6-solid:delete-left" />
                                </IconButton>
                            </m.div>
                        }
                    </AnimatePresence>
                </Box>
            </Box>
        </Box>
    )
}

PhoneNumberKeypad.propTypes = {
    phoneNumber: PropTypes.string,
    handleClickButton: PropTypes.func,
    handleDelete: PropTypes.func,
    callTo: PropTypes.func,
};

export default PhoneNumberKeypad;