import useSWR from 'swr';
import { useMemo } from 'react';

// utils
import { fetcher, endpoints } from 'src/utils/axios';

export function useListCatalogs() {
  const URL = endpoints.catalogs.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      data: data?.data[0] || [],
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && !data?.data.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
