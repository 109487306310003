import PropTypes from 'prop-types';
import { Box, Card, CardContent, Fab, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';

const message = {
    incoming: 'Incoming call',
}

export default function SnackbarCallAlert({ phonenumber, type, onAnswer, onHangUp, isOpen }) {
    return (
<Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
    }}
    sx={{ my: 2 }}
    open={isOpen}
    autoHideDuration={6000}
>
    <Card style={{ display: 'flex', padding: '8px 10px', margin: 0, width: 350, height: 100 }}>
        {/* Envuelve el CardContent en un Box para centrarlo completamente */}
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center', // Centra horizontalmente
                alignItems: 'center', // Centra verticalmente
            }}
        >
            <CardContent style={{ textAlign: 'center', padding: 0 }}>
                <Typography variant="subtitle2" component="div">
                    {phonenumber}
                </Typography>
                <Typography variant="subtitle2" color="primary" sx={{ mt: 2 }}>
                    {message[type]}
                </Typography>
            </CardContent>
        </Box>

        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={1}
        >
            <Grid item>
                <Fab
                    onClick={onAnswer}
                    color="success"
                    sx={{
                        width: 30,
                        height: 30,
                        minHeight: 'unset',
                    }}
                >
                    <CallIcon fontSize="small" />
                </Fab>
            </Grid>
            <Grid item>
                <Fab
                    onClick={onHangUp}
                    color="error"
                    sx={{
                        width: 30,
                        height: 30,
                        minHeight: 'unset',
                    }}
                >
                    <CallEndIcon fontSize="small" />
                </Fab>
            </Grid>
        </Grid>
    </Card>
</Snackbar>

    );
}

SnackbarCallAlert.propTypes = {
    phonenumber: PropTypes.string,
    type: PropTypes.string,
    onAnswer: PropTypes.func,
    onHangUp: PropTypes.func,
    isOpen: PropTypes.bool,
};

