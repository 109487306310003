import { createApi, fakeBaseQuery, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const blogBaseQuery = createApi({
    reducerPath: 'blog-firebase',
    baseQuery: fetchBaseQuery({
        baseUrl: fakeBaseQuery(),
    }),
    tagTypes: ['blog'],
    endpoints: () => ({}),
});

export default blogBaseQuery;
