import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';

// utils
import axios, { fetcher, endpoints } from 'src/utils/axios';

export function useCreateCRMSystemLayout() {
  const createData = async (url, newData) => {
    const response = await axios.post(url, newData);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  };

  return {
    mutate: async (newData) => {
      const URL = endpoints.crm.layouts.root;
      const updatedData = await createData(URL, newData);
      mutate(URL);
      return updatedData;
    },
  };
}

export function useReadCRMSystemLayout(id) {
  const URL = `${endpoints.crm.layouts.root}/${id}`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data || null,
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useUpdateCRMSystemLayout() {
  const updateData = async (url, newData) => {
    const response = await axios.put(url, newData);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  };

  return {
    mutate: async (id, newData) => {
      const URL = `${endpoints.crm.layouts.root}/${id}`;
      const updatedData = await updateData(URL, newData);
      mutate(URL);
      return updatedData;
    },
  };
}

export function useListCRMSystemLayoutsSectionLevel(section, level) {
  const URL = `${endpoints.crm.layouts.list}/${section}/${level}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      data: data?.data || [],
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && !data?.data?.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
