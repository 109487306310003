import { useEffect, useState } from 'react';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { CardActions, FormControl, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select } from '@mui/material';
// hooks
import { useLocation, useNavigate, useParams } from 'react-router';
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'react-redux';
// api
import { useGetTwilioFetchAvailablePhoneNumbers, usePostReleasePhoneNumber } from 'src/api/twilio';
// routes
import { enqueueSnackbar } from 'notistack';
// _mock
import Iconify from 'src/components/iconify';
import { usePostStripeSubscriptionCancel } from 'src/api/stripe';
import { useReadAllUsers, useReadUser } from 'src/api/users';
import { endpoints, postFetcher } from 'src/utils/axios';


// ----------------------------------------------------------------------

export default function PhoneNumberActiveDetail() {

  const mdUp = useResponsive('up', 'md');

  const params = useParams();
  const dialog = useBoolean();
  const user = useSelector((state) => state.user);
  const userTwilioSid = user.user.twilio_sid;
  const { availablephonenumbers } = useGetTwilioFetchAvailablePhoneNumbers({ userTwilioSid, sid: params.id });
  const { incomingPhoneNumbersTrigger, incomingPhoneNumbersLoading } = usePostReleasePhoneNumber();
  const { subscriptionTrigger, subscriptionLoading } = usePostStripeSubscriptionCancel();

  const handleReleasePhoneNumber = async () => {
    try {
      await incomingPhoneNumbersTrigger({ sid: params.id });
      await subscriptionTrigger({ subscription_id: 'sub_1PLJi4HKxvnTbiHizFGv0uZ4' });
      enqueueSnackbar('Phone Number Released', { variant: 'success' });
      navigate('/dashboard/contact_center/phone_numbers');
    } catch (error) {
      enqueueSnackbar('Failed To Delete Phone Number ', {
        variant: 'error',
      })
      console.error('error', error);
    }
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ownerSid = queryParams.get('owner');
  const navigate = useNavigate();
  const [personName, setPersonName] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const idOwner = user.user.account_owner ? user.user.id : user.user.owner_id;

  // Obtener los datos de los usuarios y el propietario
  const { data: users } = useReadAllUsers("all", user.organization_id, idOwner);
  const { data: userOwner } = useReadUser(idOwner);

  // useEffect para actualizar la lista de usuarios
  useEffect(() => {
    async function fetchData() {
      if (users && userOwner) {
        const ownerUser = { label: `${userOwner.firstname} ${userOwner.lastname}`, value: userOwner.id };
        const updatedUsersList = [ownerUser, ...users];
        updatedUsersList.sort((a, b) => a.label.localeCompare(b.label));
        setListUsers(updatedUsersList);

        // Si existe el ownerSid, buscar su valor y actualizar personName
        if (ownerSid) {
          const foundUser = updatedUsersList.find(u => u.value === ownerSid);
          if (foundUser) {
            setPersonName(foundUser.value);  // Asigna el nombre si se encuentra el ownerSid
          }
        }
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [users, userOwner, ownerSid]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleUpdate = async () => {
    const { status } = await postFetcher(endpoints.twilio.inconming_phone_number.update,
      {
        arg: {
          userTwilioSid,
          sid: params.id,
          newOwner: personName
        }
      });
    if (status === "success") {
      enqueueSnackbar('Update Success!', { variant: 'success' })
    } else {
      enqueueSnackbar('Error Update', { variant: 'error' });
    }
  }


  const renderActions = (
    <>
      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          color='error'
          loading={incomingPhoneNumbersLoading || subscriptionLoading}
          // sx={{ minWidth: 150 }}
          onClick={handleReleasePhoneNumber}
        >
          {/* <Typography variant="subtitle2">Release Phone Number</Typography> */}
          Release Phone Number
        </LoadingButton>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={incomingPhoneNumbersLoading || subscriptionLoading}
          // sx={{ minWidth: 150, width: {xs: 200} }}
          onClick={handleUpdate}
        >
          {/* <Typography variant="subtitle2">Save Changes</Typography> */}
          Save Changes
        </LoadingButton>

      </Grid>
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={5}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            You one-stop destination for everything related to your phone number.
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={7}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Paper>
                <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Assign To</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={personName}
                    label="Assign To"
                    onChange={handleChange}
                  >
                    {listUsers.map(userValue => (
                      <MenuItem value={userValue.value}>{userValue.label}</MenuItem>)
                    )}
                  </Select>
                </FormControl>
                <Typography variant="subtitle1" sx={{ paddingTop: 2 }}>
                  Phone Number
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {availablephonenumbers?.friendlyName || 'N/A'}
                </Typography>
                <Typography variant='body2' sx={{ color: 'text.disabled' }}>US</Typography>
              </Paper>
            </Stack>

            <Stack spacing={1.5}>
              <Paper>
                <Typography variant="subtitle1">
                  Monthly Fee
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  $1.15/month
                </Typography>
              </Paper>
            </Stack>

            <Stack spacing={1.5}>
              <Paper>
                <Typography variant="subtitle1">
                  Capabilities
                </Typography>
                <List>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemIcon>
                      <Iconify
                        icon="lets-icons:check-fill"
                        width={24}
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }} />
                    </ListItemIcon>
                    <ListItemText primary='Voice' secondary="Receive incoming calls and make outgoing calls" />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemIcon>
                      <Iconify
                        icon="lets-icons:check-fill"
                        width={24}
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }} />
                    </ListItemIcon>
                    <ListItemText primary='SMS' secondary="Send and receive text messages" />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <ListItemIcon>
                      <Iconify
                        icon="lets-icons:check-fill"
                        width={24}
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }} />
                    </ListItemIcon>
                    <ListItemText primary='MMS' secondary="Send and receive multi media messages" />
                  </ListItem>
                </List>
              </Paper>
            </Stack>

            <Stack spacing={1.5}>
              <Paper>
                <Typography variant="subtitle1">
                  A2P 10DLC Registration Required For US Messaging.
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  To send SMS/MMS Messages To The US With This US Local Number, A Registration Process Is Required. This Process Can Be Performed After The Number Is Purchased
                  <Typography component="a" variant='body2' href='#' target='_blank'> Learn More About A2P 10DLC Registration</Typography>
                </Typography>
              </Paper>
            </Stack>
          </Stack>
          <CardActions>
            {renderActions}
          </CardActions>
        </Card>
      </Grid>
    </>
  );

  return (
    <Grid container spacing={3}>
      {renderDetails}
    </Grid>
  );
}