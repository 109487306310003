import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
    contact_notes: []
};

const slice = createSlice({
  name: 'contact_notes',
  initialState,
  reducers: {
    setContact_notes(state, action) {
        const { contact_notes } = action.payload;
        state.contact_notes = contact_notes;
    },
    addContact_note(state, action) {
      const { contact_note } = action.payload;
      state.contact_notes.push(contact_note);
    },
    updateContact_note(state, action) {
      const { contact_note } = action.payload;
      const index = findIndex(state.contact_notes, { id: contact_note.id });

      if (index !== -1) {
        state.contact_notes[index] = contact_note;  
      }
      
    },
    deleteContact_note(state, action) {
      const { contact_note } = action.payload;
      const index = findIndex(state.contact_notes, { id: contact_note.id });

      if (index !== -1) {
        state.contact_notes.splice(index, 1);
      }
      
    },
    clearContact_note(state) {
      state.contact_notes = initialState.contact_notes;
      state.actionType = initialState.actionType;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setContact_notes,
    addContact_note,
    updateContact_note,
    deleteContact_note,
    clearContact_note
} = slice.actions;