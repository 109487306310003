import { Device } from '@twilio/voice-sdk'
import { endpoints, fetcher } from 'src/utils/axios';

export const getTokens = async (params) => {
    const URL = [endpoints.twilio.token, { params }];
    const { data: token } = await fetcher(URL);
    return token;
}

export const setupDevice = async (identity) => {
    try {
        const token = await getTokens({ identity });
        const device = new Device(token, { logLevel: 'debug' });

        device.on('incoming', (connection) => {
            console.log(`Incoming connection from ${connection}`);
            connection.accept();
            setupAudio(connection);
        });

        device.on('ready', () => {
            console.log('Twilio.Device Ready');
        });

        device.on('cancel', () => {
            console.log('Call canceled');
        });

        device.on('disconnect', () => {
            console.log('MASTER LOG: Call ended');
        });

        device.on('error', (error) => {
            console.error(`Twilio.Device Error: ${error.message}`);
        });

        return device;

    }catch(error){
        console.error(error);
        return null;
    }
}

const setupAudio = (connection) => {
    const audio = document.createElement('audio');
    audio.autoplay = true;

    connection.on('accept', () => {
        console.log('Call accepted');
    });

    connection.on('cancel', () => {
        console.log('Call cancelled');
    });

    connection.on('disconnect', () => {
        console.log('Call disconnected');
    });
};