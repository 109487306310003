import { STRIPE } from "./config-global";

export const STRIPE_PLANS = [
    {
        id: STRIPE.monthly_id,
        subscription: 'Monthly',
        price: 99.00
    },
    {
        id: STRIPE.yearly_id,
        subscription: 'Annual',
        price: 900.00
    }
];

export const Message = {
    success: 'Update Success!'
}