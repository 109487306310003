import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
    contacts: []
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContacts(state, action) {       
        const { contacts } = action.payload;
        state.contacts = [...contacts];

    },
    updateContact(state, action) {
      const { contact } = action.payload;
      const index = findIndex(state.contacts, { id: contact.id });

      if (index !== -1) {
        state.contacts[index] = contact;  
      }
      
    },
    addContactnote(state, action) {
      const { contact } = action.payload;
      const index = findIndex(state.contacts, { id: contact.id });

      if (index !== -1) {
        state.contacts[index].notes.push(contact.notes);  
      }
      
    },
    deleteContact(state, action) {
      const { contact } = action.payload;
      const index = findIndex(state.contacts, { id: contact.id });

      if (index !== -1) {
        state.contacts.splice(index, 1);
      }
      
    },
    addContact(state, action) {
      const { contact } = action.payload;
      state.contacts.push(contact);
    },
    clearContacts(state) {
        state.contacts = initialState.contacts;
        state.actionType = initialState.actionType;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setContacts,
    clearContacts,
    updateContact,
    addContactnote,
    deleteContact,
    addContact,
} = slice.actions;
