// @mui
import Container from '@mui/material/Container';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import PhoneNumberPurchase from '../phone-number-purchase';
import CallLog from '../call-log';

// ----------------------------------------------------------------------

export default function CallView() {
    
  return <CallLog/>
}
