export const property_types = [
    {
        label: 'Single Family Residence',
        checked: true,
        type: "housing"
    },
    {
        label: 'Condominium',
        checked: true,
        type: "housing"
    },
    {
        label: 'Townhouse',
        checked: true,
        type: "housing"
    },
    {
        label: 'Manufactured on land',
        checked: true,
        type: "housing"
    },
    {
        label: 'Loft',
        checked: true,
        type: "housing"
    },
    {
        label: 'Cabin',
        checked: true,
        type: "housing"
    },
    {
        label: 'Duplex',
        checked: true,
        type: "unit"
    },
    {
        label: 'Triplex',
        checked: true,
        type: "unit"
    },
    {
        label: 'Quadraplex',
        checked: true,
        type: "unit"
    },
    {
        label: 'Multi Family',
        checked: false,
        type: "other"
    },
    {
        label: 'Agriculture',
        checked: false,
        type: "other"
    },
    {
        label: 'Boat Slip',
        checked: false,
        type: "other"
    },
    {
        label: 'Farm',
        checked: false,
        type: "other"
    },
    {
        label: 'Industrial',
        checked: false,
        type: "other"
    },
    {
        label: 'Unimproved Land',
        checked: false,
        type: "other"
    },
    {
        label: 'Warehouse',
        checked: false,
        type: "other"
    },
  ];
  