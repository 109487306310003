import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'MMM dd yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'MMM dd yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fTimeDifference(startTime, endTime) {
  const start = moment(startTime, 'HH:mm:ss a');
  const end = moment(endTime, 'HH:mm:ss a');

  return `${moment.duration(end.diff(start)).hours()}h ${moment.duration(end.diff(start)).minutes()}m ${moment.duration(end.diff(start)).seconds()}s`;

}