import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { rootReducer } from './root-reducer';
import bridgeDataOutPutBaseQuery from './services/bridgedataoutput/api';
import validatorPhoneAndEmailBaseQuery from './services/salesrush/api';
import blogBaseQuery from './services/blog/api';

// ----------------------------------------------------------------------

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      validatorPhoneAndEmailBaseQuery.middleware, 
      bridgeDataOutPutBaseQuery.middleware,
      blogBaseQuery.middleware)
});

export const persistor = persistStore(store);

export const useSelector = useAppSelector;

export const useDispatch = () => useAppDispatch();
