import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// api
import { useGetSubscription } from 'src/api/user';
//
import { useDispatch, useSelector } from 'src/redux/store';
import { setUpdateSubscription } from 'src/redux/slices/user';


// ----------------------------------------------------------------------

export default function AuthSubscription({ children }) {

  const router = useRouter();

  const dispatch = useDispatch();

  const { mutate } = useGetSubscription();

  const { subscription, user } = useSelector(state => state.user);

  const [checked, setChecked] = useState(false);

  const check = useCallback(async () => {
    
    const currentTime = Date.now() / 1000;

    if (currentTime > subscription.current_period_end ) {
        
        mutate(user).then(response => {
          dispatch(setUpdateSubscription({ subscription: response.subscription }));
        });
    } 

    if(subscription.status === 'paused' || subscription.status === 'canceled' || subscription.status === 'past_due') {
      router.replace(paths.dashboard.reactivate);      
    }

    setChecked(true);
  }, [subscription, mutate, user, dispatch, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthSubscription.propTypes = {
  children: PropTypes.node,
};
