/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import LandingQuickEditForm from './landing-quick-edit-form';

// ----------------------------------------------------------------------

export default function LandingTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, onActivateRow }) {

  console.log(row)

  const { page_name, url_path, save_submission_to, created_by, id, status } = row;

  const router = useRouter();

  const confirm = useBoolean();

  const confirm_activate = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();


  const handleNabigate = (ids) => {
    router.push(paths.dashboard.landing.edit(ids));
  }

  const handleactivate = () => {
    confirm_activate.onFalse();
    onActivateRow();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Typography
            component="span"
            color="inherit"
            variant="subtitle2"
            noWrap
            sx={{ textTransform: 'capitalize' }}
            onClick={() => handleNabigate(id)}
          >
            {`${page_name}`}
          </Typography>
        </TableCell>



        <TableCell sx={{ whiteSpace: 'nowrap' }}>{`/${url_path}`}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{0}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{save_submission_to}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{created_by}</TableCell>

        {/* <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell> */}


        {/* <Tooltip title='Delete' placement="top" arrow>
            <IconButton
              color="default"
              onClick={popover.onOpen}

            >
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip> */}


        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton

              color="default"
              onClick={(event) => {

                onEditRow();
                popover.onClose();

              }}
              sx={{ color: created_by === 'Sales Rush' ? grey[400] : '' }}

            >
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <LandingQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover> */}


      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {status === 'active' && (
          <MenuItem
            onClick={() => {
              if (created_by === 'Sales Rush') return
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: created_by === 'Sales Rush' ? grey[400] : 'error.main' }}
          >
            <Iconify icon="wpf:restriction-shield" />
            Deactivate
          </MenuItem>
        )}

        {status === 'inactive' && (
          <MenuItem

            onClick={() => {

              if (created_by === 'Sales Rush') return

              confirm_activate.onTrue();
              popover.onClose();
            }}
            sx={{ color: created_by === 'Sales Rush' ? grey[400] : 'success.main' }}
          >
            <Iconify
              icon="solar:shield-check-bold" />
            Activate
          </MenuItem>
        )}

        <MenuItem

          onClick={() => {

            onEditRow();
            popover.onClose();
          }}
          sx={{ color: created_by === 'Sales Rush' ? grey[400] : '' }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>


      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow()
            confirm.onFalse()
          }}>
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={confirm_activate.value}
        onClose={confirm_activate.onFalse}
        title="Activate"
        content="Are you sure want to Activate?"
        action={
          <Button variant="contained" color="success" onClick={handleactivate}>
            Activate
          </Button>
        }
      />
    </>
  );
}

LandingTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onActivateRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
