import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
// material ui
import { Fab, Fade, useScrollTrigger } from '@mui/material';
//
import Logo from 'src/components/logo';
//
import { paths } from 'src/routes/paths';
import Footer from './footer';
import Header from './header';

// ----------------------------------------------------------------------

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function MainLayout(props) {

  const { children } = props
  const pathname = usePathname();

  const isHome = pathname === '/';
  const isListingHomeForSale = pathname === paths.listings.homes;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }} id="back-to-top-anchor">
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {children}
      </Box>
      {
        !isListingHomeForSale &&  <Footer />
      }
      <ScrollTop {...props}>
        <Fab aria-label="scroll back to top" variant='default'>
          <Logo/>
        </Fab>
      </ScrollTop>
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
