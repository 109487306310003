export const object_types = [
  {
    value: 'contact',
    label: 'Contact',
    description: `Contacts represent more established relationships than leads, who are unqualified potential customers and
    opportunities wich are qualified prospects. They are crucial for managing ongoing communications throughout the sales
    process.`
  },
  {
    value: 'lead',
    label: 'Lead',
    description: `
      A lead is a potential customer who has expressed interest in your product or service but hasn't been fully qualified.
      Leads are gathered from various sources, such as marketing campaigns or inquiries, and are evaluated to determine their
      potential. Once qualified, they can be converted into opportunities for active sales engagement.`
  },
  {
    value: 'opportunity',
    label: 'Opportunity',
    description: `An opportunity is a qualified prospect actively engaged in the sales process, representing a potential deal
    with clear intent to purchase. Opportunities progress through stages like proposal, negotiation and closing, aiming to
    convert prospects into customers.`
  },
  {
    value: 'company',
    label: 'Company',
    description: `Companies represent a business or organization linked to multiple contacts, leads, or opportunities helping you manage all
    interactions and sales efforts related to that organization.`
  },
];

export const field_types = [
  { value: 'text', label: 'Text field' },
  { value: 'email', label: 'Email Field' },
  { value: 'phone', label: 'Phone Number' },
  { value: 'select_single', label: 'Single Select' },
  { value: 'date_picker', label: 'Date Picker' },
  { value: 'number', label: 'Number' },
  { value: 'select_multiple', label: 'Multiple Select' },
  { value: 'address', label: 'Address' },
  { value: 'city', label: 'City' },
  { value: 'region', label: 'State' },
  { value: 'postcode', label: 'Zip Code' },
];

export const field_formats = [
  { value: 'formatted', label: 'Formatted number' },
  { value: 'unformulated', label: 'Unformulated number' },
  { value: 'percentage', label: 'Percentage number' },
  { value: 'currency', label: 'Currency' },
];

export const field_sort = [
  { value: 'asc', label: 'Alphabetical' },
  { value: 'custom', label: 'Custom' },
];


export const field_defined = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
