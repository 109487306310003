import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
    reviews: []
};

const slice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setReviews(state, action) {
        const { reviews } = action.payload;
        state.reviews = reviews;
    },
    addReview(state, action) {
      const { review } = action.payload;
      state.reviews.push(review);
    },
    updateReview(state, action) {
      const { review } = action.payload;
      const index = findIndex(state.reviews, { id: review.id });

      if (index !== -1) {
        state.reviews[index] = review;  
      }
      
    },
    deleteReview(state, action) {
      const { review } = action.payload;
      const index = findIndex(state.reviews, { id: review.id });

      if (index !== -1) {
        state.reviews.splice(index, 1);
      }
      
    },
    clearReviews(state) {
      state.reviews = initialState.reviews;
      state.actionType = initialState.actionType;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setReviews,
    addReview,
    updateReview,
    deleteReview,
    clearReviews,
} = slice.actions;