import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector } from 'src/redux/store';
import { HeaderSimple as Header } from '../_common';
import AppWelcome from '../../sections/overview/app/app-welcome';

// ----------------------------------------------------------------------

export default function ReactivateLayout({ children }) {

  const { user } = useSelector(state => state.user);

  return (
    <>
      <Header />

      <Container
      sx={{
        pt: 5,
        pb: 10,
        minHeight: 1,
      }}
    >
        <Grid 
        container 
        justifyContent='center'
        rowSpacing={{ xs: 5, md: 0 }} 
        columnSpacing={{ xs: 0, md: 5 }}>

          <Grid xs={12} md={12} sx={{ mb:2 }}>
            <AppWelcome
              title={`Welcome Back \n ${user?.firstname} ${user?.lastname}`}
              description="We're thrilled to see you return to Sales Rush, To ensure you continue to experience the full benefits of our services, we kindly request reactivating your sales rush plan."
            />
          </Grid>
          <Grid xs={12} md={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
      
    </>
  );
}

ReactivateLayout.propTypes = {
  children: PropTypes.node,
};
