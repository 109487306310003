import React, { useRef } from 'react';
import { useWavesurfer } from '@wavesurfer/react';
import { useTheme } from '@emotion/react';
import { Box, Fab, Icon, IconButton, Popover, Slide, Slider, Stack, Typography } from '@mui/material';
import { Pause, PlayArrow, VolumeDown, VolumeUp } from '@mui/icons-material';
import PropTypes from 'prop-types';


const AudioPlayer = ({ urlRecording }) => {
    const containerRef = useRef(null);

    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'volume-popover' : undefined;

    const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
        container: containerRef,
        url: urlRecording || 'https://api.twilio.com/2010-04-01/Accounts/AC90af363c7e714581da7563760278e03d/Recordings/RE0d2ef737ed86cf564273383997f93bbd',
        waveColor: theme.palette.primary.light,
        progressColor: theme.palette.primary.main,
        height: 100,
        width: '100%',
    })

    const TimeStamp = () => {
        const formatTime = (seconds) => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')
        return (
            <Box>
                {formatTime(currentTime)}
            </Box>
        )
    }

    const onPlayPause = () => {
        if (wavesurfer) wavesurfer.playPause()
    }

    return (
        <Stack direction="row"
            component='div'
            sx={{ height: 'auto', width: '100%' }}
            alignItems="center">
            <IconButton
                color='primary'
                sx={{ m: 1 }}
                onClick={onPlayPause}>
                {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <Stack direction="row"
                component={Box}
                height='100%'
                width='100%'
                alignItems='center'
                spacing={1}>
                <TimeStamp />
                <Box flexGrow={1} height="100%" width="100%" alignItems="center">
                    <div ref={containerRef} style={{ flex: 1 }} />
                </Box>
                <Stack spacing={2} direction="row" alignItems="center">
                    <Fab size="small" color='inherit' aria-describedby={id} variant="contained" onClick={handleClick}
                        sx={{
                            position: 'relative', // Cambia esto si el botón está en un contenedor con posicionamiento especial
                            zIndex: 10, // Ajusta el z-index según sea necesario
                        }}
                    >
                        <VolumeDown color='primary' />
                    </Fab>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        sx={{ width: 200 }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <Slider
                            size='small'
                            defaultValue={1}
                            step={0.1}
                            min={0}
                            max={1}
                            onChange={(e, value) => wavesurfer.setVolume(value)}
                            aria-labelledby="continuous-slider" sx={{ width: 100, mx: 1 }} />
                    </Popover>
                </Stack>
            </Stack>
        </Stack>
    )
};


export default AudioPlayer;

AudioPlayer.propTypes = {
    urlRecording: PropTypes.string
};