import { useEffect, useCallback, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSelector } from 'react-redux';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hook';
// api
// import { useGetLabels } from 'src/api/mail';
// components
import EmptyContent from 'src/components/empty-content';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSettingsContext } from 'src/components/settings';
// _mock
// import { CallLabels } from 'src/_mock/_contact_center';
import { useGetTwilioCallsLogs } from 'src/api/twilio';
//
import { endpoints, postFetcher } from 'src/utils/axios';
import { useTwilio } from '../../twilio-provider';
import CallNav from '../call-nav';
import CallList from '../call-list';
import CallHeader from '../call-header';
import CallCompose from '../call-compose';
import CallDetails from '../call-details';




// ----------------------------------------------------------------------

const LABEL_INDEX = 'inbox';

export default function CallView() {

  const router = useRouter();

  const searchParams = useSearchParams();

  // const selectedLabelId = searchParams.get('label') || LABEL_INDEX;

  const selectedMailId = searchParams.get('id') || '';

  const upMd = useResponsive('up', 'md');

  const settings = useSettingsContext();

  const [callLogSelected, setCallLogSelected] = useState(null);

  const [selectedLabelId, setSelectedLabelId] = useState("recents");

  const [selectRecordingUrl, setSelectRecordingUrl] = useState(null);

  const openNav = useBoolean();

  const openMail = useBoolean();

  const openCompose = useBoolean();
  const user = useSelector((state) => state.user);
  const { userTwilioPhone } = useTwilio();
  const userTwilioSid = user.user.twilio_sid;
  const userId = user.user.id;
  // const userTwilioPhone = user.user.twilio_phone;
  const { callsLog, callsLogLoading, callsLogEmpty } = useGetTwilioCallsLogs({ userTwilioSid, userTwilioPhone, selectedLabelId, userId });

  useEffect(() => {

  }, [callsLog, openCompose]);

  // const { labels, labelsLoading } = useGetLabels();

  // const { mails, mailsLoading, mailsError, mailsEmpty } = useGetMails(selectedLabelId);

  // const { mail, mailLoading, mailError } = useGetMail(selectedMailId);

  // const firstMailId = mails.allIds[0] || '';
  const CallLabels = [
    {
      id: "recents",
      unreadCount: null,
      name: 'Recents',
    },
    {
      id: "missed",
      unreadCount: null,
      name: 'Missed',
    },
    {
      id: "voicemail",
      unreadCount: null,
      name: 'Voicemail',
    }
  ]

  const handleToggleCompose = useCallback(() => {
    if (openNav.value) {
      openNav.onFalse();
    }
    openCompose.onToggle();
  }, [openCompose, openNav]);

  const handleClickLabel = useCallback(

    (labelId) => {
      // console.log("labelId", labelId)
      if (!upMd) {
        openNav.onFalse();
      }

      if (labelId) {
        // const href =
        //   labelId !== LABEL_INDEX
        //     ? `${paths.dashboard.contact_center.calls}?label=${labelId}`
        //     : paths.dashboard.contact_center.calls;

        // router.push(href);
        setSelectedLabelId(labelId)
        setCallLogSelected(null)
        setSelectRecordingUrl(null)
      }
    },
    [openNav, upMd]
  );

  const recordingURL = async (callLogItem) => {
    if (!callLogItem.recordingurl) {
      try {
        const { data, status } = await postFetcher(endpoints.twilio.conference.recording.callSid, {
          arg: {
            CallSid: callLogItem.sid,
            AccountSid: userTwilioSid
          }
        });
        if (data) {
          setSelectRecordingUrl(data.recordingUrl)
        }

      } catch (error) {
        console.error("Error fetching recording:", error);
      }
    } else {
      setSelectRecordingUrl(callLogItem.recordingurl)
    }
  };

  const handleClickMail = useCallback(
    (callLogItem) => {
      setCallLogSelected(callLogItem);
      recordingURL(callLogItem)
    },
    // eslint-disable-next-line
    []
  );

  /* useEffect(() => {
    if (mailsError || mailError) {
      router.push(paths.dashboard.mail);
    }
  }, [mailError, mailsError, router]); */

  /* useEffect(() => {
    if (!selectedMailId && firstMailId) {
      handleClickMail(firstMailId);
    }
  }, [firstMailId, handleClickMail, selectedMailId]); */

  useEffect(() => {
    if (openCompose.value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openCompose.value]);

  const renderLoading = (
    <LoadingScreen
      sx={{
        borderRadius: 1.5,
        bgcolor: 'background.default',
      }}
    />
  );

  const renderEmpty = (
    <EmptyContent
      title={`Nothing in ${selectedLabelId}`}
      imgUrl="/assets/icons/empty/ic_folder_empty.svg"
      sx={{
        borderRadius: 1.5,
        maxWidth: { md: 320 },
        bgcolor: 'background.default',
      }}
    />
  );

  const renderCallNav = (
    <CallNav
      loading={false}
      openNav={openNav.value}
      onCloseNav={openNav.onFalse}
      //
      labels={CallLabels}
      selectedLabelId={selectedLabelId}
      handleClickLabel={handleClickLabel}
      //
      onToggleCompose={handleToggleCompose}
    />
  );

  const renderMailList = (
    <CallList
      mails={callsLog}
      loading={callsLogLoading}
      //
      openMail={openMail.value}
      onCloseMail={openMail.onFalse}
      onClickMail={handleClickMail}
      //
      selectedLabelId={selectedLabelId}
      selectedMailId={selectedMailId}
    />
  );

  const renderCallDetails = (
    <>
      {callsLogEmpty || !callLogSelected ? (
        <EmptyContent
          title="No log selected"
          imgUrl="/assets/icons/empty/ic_email_disabled.svg"
          sx={{
            borderRadius: 1.5,
            bgcolor: 'background.default',
            ...(!upMd && {
              display: 'none',
            }),
          }}
        />
      ) : (
        <CallDetails
          mail={callLogSelected}
          renderLabel={selectedLabelId}
          selectRecordingUrl={selectRecordingUrl}
        />
      )}
    </>
  );

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <Typography
          variant="h4"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          Calls
        </Typography>

        <Stack
          spacing={1}
          sx={{
            p: 1,
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
            bgcolor: 'background.neutral',
          }}
        >
          {!upMd && (
            <CallHeader
              onOpenNav={openNav.onTrue}
              onOpenMail={true ? null : openMail.onTrue}
            />
          )}

          <Stack
            spacing={1}
            direction="row"
            flexGrow={1}
            sx={{
              height: {
                xs: '72vh',
              },
            }}
          >
            {renderCallNav}

            {callsLog && !callsLogLoading ? renderMailList : renderEmpty}

            {renderCallDetails}
          </Stack>
        </Stack>
      </Container>

      {openCompose.value && <CallCompose onCloseCompose={openCompose.onFalse} />}
    </>
  );
}
