import { useMemo } from "react";
import { endpoints, fetcher, postFetcher } from "src/utils/axios";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export function usePostStripeSubscriptionCreate() {

  const URL = endpoints.stripe.subscription.create;
  
  const { trigger, isMutating, data, error } = useSWRMutation(URL, postFetcher);

  const memoizedValue = useMemo(
    () => ({
      subscription: data?.data || [],
      subscriptionLoading: isMutating,
      subscriptionError: error,
      subscriptionTrigger: trigger,
    }),
    [data?.data, error, isMutating, trigger]
  );

  return memoizedValue;
}

export function usePostStripeSubscriptionCancel() {
    
      const URL = endpoints.stripe.subscription.cancel;
      
      const { trigger, isMutating, data, error } = useSWRMutation(URL, postFetcher);
    
      const memoizedValue = useMemo(
     () => ({
        subscription: data?.data || [],
        subscriptionLoading: isMutating,
        subscriptionError: error,
        subscriptionTrigger: trigger,
     }),
     [data?.data, error, isMutating, trigger]
      );
    
      return memoizedValue;
    
}

export function useGetStripePaymentMethods() {
  const URL = endpoints.stripe.payment_method.list;

  const { data, error, mutate } = useSWR(URL, fetcher);

  return {
    paymentMethods: data?.data || [],
    paymentMethodsLoading: !data && !error,
    paymentMethodsError: error,
    paymentMethodsMutate: mutate,
  };
}
