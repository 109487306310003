// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { TextGradient } from 'src/components/text-gradient';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
// import { paths } from 'src/routes/paths';
// components
//
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import { SettingsButton, HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar>
        <Toolbar
          disableGutters
          sx={{
            marginRight: {
              md: '1.5em',
            },
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            },
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(offsetTop && {
              ...bgBlur({
                color: theme.palette.background.default,
              }),
              height: {
                md: HEADER.H_DESKTOP_OFFSET,
              },
            }),
          }}
        >
          <Container sx={{ height: 1, display: 'flex' }} maxWidth="100%">
            {/* <Logo /> */}
            <TextGradient
              animate={{ backgroundPosition: '200% center' }}
              transition={{
                repeatType: 'reverse',
                ease: 'linear',
                duration: 20,
                repeat: Infinity,
              }}
              title="Mortgage Pro"
            />
            <Box sx={{ flexGrow: 1 }} />

            {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

            <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
              <SettingsButton
                sx={{
                  ml: { xs: 1, md: 0 },
                  mr: { md: 2 },
                }}
              />

              {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
            </Stack>
          </Container>
        </Toolbar>

        {offsetTop && <HeaderShadow />}
      </AppBar>
    </Box>
  );
}
