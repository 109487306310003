import { LandingEditView } from "src/sections/landing/view";
// ----------------------------------------------------------------------

export const metadata = {
  title: 'Dashboard: Create a new landing',
};

export default function LandingCreatePage() {
  return <LandingEditView />;
}
