
import salesRushApiBaseQuery from "./api";


const salesrushApi = salesRushApiBaseQuery.injectEndpoints({
    endpoints: (build) => ({
        getFormData: build.query({
            query (body) {
                return {
                    url: 'contact',
                    method: 'POST',
                    body // { contact_id }
                }
            }
        }),
        stripeSetupIntent: build.query({
            query (body) {
                return {
                    url: 'stripe/setupintent',
                    method: 'POST',
                    body // { client_id }
                }
            }
        }),
        stripeSetCustomerDefaultPaymentMethod: build.query({
            query (body) {
                return {
                    url: 'stripe/customer/defaultpayment',
                    method: 'POST',
                    body // { customer_id, payment_method_id }
                }
            }
        }),
        stripeListPaymentMethods: build.query({
            query (body) {
                return {
                    url: 'stripe/paymentmethod/list',
                    method: 'POST',
                    body // { customer }
                }
            }
        }),
        stripeUpdateSubscription: build.query({
            query (body) {
                return {
                    url: 'stripe/changesubscription',
                    method: 'POST',
                    body // { subscription_id, sub_item_id, price_id }
                }
            }
        }),
        stripeCancelSubscription: build.query({
            query (body) {
                return {
                    url: 'stripe/cancelsubscription',
                    method: 'POST',
                    body // { subscription_id}
                }
            }
        }),
        stripeReactiveSubscription: build.query({
            query (body) {
                return {
                    url: 'stripe/reactivesubscription',
                    method: 'POST',
                    body // { subscription_id }
                }
            }
        }),
        stripeUpdateUserData: build.query({
            query (body) {
                return {
                    url: 'stripe/updateUserData',
                    method: 'POST',
                    body // { subscription_id }
                }
            }
        }),
        stripeDetachPaymentMethod: build.query({
            query (body) {
                return {
                    url: 'stripe/detachpaymentmethod',
                    method: 'POST',
                    body // { payment_method_id }
                }
            }
        }),
        register: build.query({
            query (body) {
                return {
                    url: 'auth/register',
                    method: 'POST',
                    body // { user }
                }
            }
        }),
        authValidatePhone: build.query({
            query (body) {
                return {
                    url: 'auth/validate/phone',
                    method: 'POST',
                    body // { cell_phone_number }
                }
            }
        }),
        authValidateEmail: build.query({
            query (body) {
                return {
                    url: 'auth/validate/email',
                    method: 'POST',
                    body // { cell_phone_number }
                }
            }
        }),
        validateSubdomain: build.query({
            query (body) {
                return {
                    url: 'auth/validate/subdomain',
                    method: 'POST',
                    body // { subdomain }
                }
            }
        }),
        getUserData: build.query({
            query (body) {
                return {
                    url: 'auth/login',
                    method: 'POST',
                    body // { phone }
                }
            }
        }),
        postValidationPhone: build.query({
            query (body) {
                return {
                    url: 'account/validate/phone',
                    method: 'POST',
                    body // { phone }
                }
            }
        }),
        postValidationEmail: build.query({
            query (body) {
                return {
                    url: 'account/validate/email',
                    method:  'POST',
                    body // { email }
                }
            }
        }),
        postSendPhoneCode: build.query({
            query (body) {
                return {
                    url: 'send/sms',
                    method: 'POST',
                    body // { to: phone, clientID }
                }
            }
        }),
        postSendEmailCode: build.query({
            query (body) {
                return {
                    url: 'send/email',
                    method: 'POST',
                    body // { to: email, clientID }
                }
            }
        }),
        postStoreClient: build.query({
            query (body) {
                return {
                    url: 'client',
                    method: 'POST',
                    body // { lead }
                }
            }
        }),
        postStoreContact: build.query({
            query (body) {
                return {
                    url: 'client/create/contact',
                    method: 'POST',
                    body // { contact }
                }
            }
        }),
        putStoreContact: build.query({
            query (body) {
                return {
                    url: `client/${body.id}`,
                    method: 'PUT',
                    body
                }
            }
        }),
        postValidationPhoneCode: build.query({
            query(body) {
                return {
                    url: 'validate/code',
                    method: 'POST',
                    body // { code, clientID }
                }
            }
        })
    }),
    overrideExisting: false,
});

export const { useLazyPostValidationPhoneQuery,
     useLazyPostValidationEmailQuery,
      useLazyPostStoreClientQuery,
      useLazyPostSendPhoneCodeQuery, 
      useLazyPostSendEmailCodeQuery, 
      useLazyPostValidationPhoneCodeQuery,
      useLazyGetUserDataQuery,
      useLazyRegisterQuery,
      useStripeSetupIntentQuery,
      useLazyStripeSetupIntentQuery,
      useLazyStripeSetCustomerDefaultPaymentMethodQuery,
      useLazyStripeListPaymentMethodsQuery,
      useLazyStripeUpdateSubscriptionQuery,
      useLazyStripeCancelSubscriptionQuery,
      useLazyStripeReactiveSubscriptionQuery,
      useLazyAuthValidatePhoneQuery,
      useLazyAuthValidateEmailQuery,
      useLazyStripeDetachPaymentMethodQuery,
      useLazyPostStoreContactQuery,
      useLazyPutStoreContactQuery,
      useLazyValidateSubdomainQuery,
      useGetFormDataQuery,
      useLazyStripeUpdateUserDataQuery,
    } = salesrushApi;
