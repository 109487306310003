// config
import { TYPESENSE_CONFIG } from 'src/config-global';

// ----------------------------------------------------------------------
const Typesense = require('typesense');

export const clientTypesense = new Typesense.Client({
  nodes: [
    {
      host: TYPESENSE_CONFIG.host,
      port: TYPESENSE_CONFIG.port,
      protocol: TYPESENSE_CONFIG.protocol,
    },
  ],
  apiKey: TYPESENSE_CONFIG.api_key,
  connectionTimeoutSeconds: 2,
});
