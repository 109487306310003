import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
import { SALESRUSHAPI } from 'src/config-global';
// utils
import { fetcher } from 'src/utils/axios';

export function useCreateCRMField() {
  const createData = async (url, newData) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  return {
    mutate: async (newData) => {
      const url = `${SALESRUSHAPI.api_url}/user_fields`;
      const createdData = await createData(url, newData);
      mutate(url);
      // Return the created data
      return createdData;
    },
  };
}

export function useReadCRMField(id) {
  const URL = `${SALESRUSHAPI.api_url}/user_fields/${id}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data || null,
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useReadCRMFieldsByUser(user_id) {
  const URL = `${SALESRUSHAPI.api_url}/user_fields/user/${user_id}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data?.data || [],
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && !data?.data.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useUpdateCRMField() {
  const updateData = async (url, newData) => {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  return {
    mutate: async (id, newData) => {
      const url = `${SALESRUSHAPI.api_url}/user_fields/${id}`;
      const updatedData = await updateData(url, newData);
      mutate(url);
      // Return the updated data
      return updatedData;
    },
  };
}

export function useReadAllCRMFieldsByUser(user_id, user_level) {


  const URL = `${SALESRUSHAPI.api_url}/user_fields/all/${user_id}/${user_level}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      data: data?.data || [],
      dataLoading: isLoading,
      dataError: error,
      dataValidating: isValidating,
      dataEmpty: !isLoading && !data?.data.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
