// @mui
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// ----------------------------------------------------------------------

export default function TableSkeletonPhoneNumber({ ...other }) {
  return (
    <TableRow {...other}>
      <TableCell colSpan={12}>
        <Stack spacing={3} direction="row" alignItems="center">
          <Skeleton sx={{ width: 300, height: 10 }} />
          <Skeleton sx={{ width: 110, height: 12 }} />
          <Skeleton sx={{ width: 110, height: 12 }} />
          <Skeleton sx={{ width: 110, height: 12 }} />
          <Skeleton sx={{ width: 110, height: 12 }} />
          <Skeleton sx={{ width: 110, height: 12 }} />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
