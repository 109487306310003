import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import moment from 'moment';
import { useEffect } from 'react';
// components
import AudioPlayer from 'src/components/audio-player/audio-player';
import EmptyContent from 'src/components/empty-content';
// import CallCollapsibleLog from './call-collapsible-log';
import { useSelector } from 'react-redux';
import { endpoints, postFetcher } from 'src/utils/axios';
import ParticipantsTable from './call-conference-details';



// ----------------------------------------------------------------------

export default function CallDetails({ mail, renderLabel, selectRecordingUrl}) {
  const showAttachments = useBoolean(true);
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const userTwilioSid = user.user.twilio_sid;

  if (!mail) {
    return (
      <EmptyContent
        title="No Conversation Selected"
        description="Select a conversation to read"
        imgUrl="/assets/icons/empty/ic_email_selected.svg"
        sx={{
          borderRadius: 1.5,
          bgcolor: 'background.default',
        }}
      />
    );
  }

  const handleClick = () => {

  }

  // CallsRender
  const renderHead = (
    <Stack direction="row" alignItems="center" flexShrink={0} sx={{ height: 56, pl: 2, pr: 1 }}>
      {/* Contenedor izquierdo */}
      <Stack direction="row" alignItems="center" spacing={1}>
        <Chip
          variant="outlined"
          label={
            <span>
              <strong>DATE</strong> {moment(mail.dateCreated).format('LL')} {moment(mail.dateCreated).format('h:mm:ss a')}
            </span>
          }
        />
        <Chip
          variant="outlined"
          label={
            <span>
              <strong>From</strong> {mail.from}
            </span>
          }
        />
      </Stack>

      {/* Contenedor derecho */}
      <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 'auto' }}>
        <Chip
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Iconify icon="si:phone-fill" width="1.2rem" height="1.2rem" />
              <span style={{ marginLeft: '0.5rem' }}>Call</span>
            </span>
          }
          onClick={handleClick}
        />
      </Stack>
    </Stack>
  );

  const renderCallDetails = (
    <Stack direction="row" alignItems="center" flexShrink={0} sx={{ p: 2 }}>
      <Stack direction="row" spacing={1} flexGrow={1}>
        {/* <CallCollapsibleLog row={mail} /> */}
        <ParticipantsTable />
      </Stack>
    </Stack>
  );
  // selectRecordingUrl
  const renderRecordingCall = selectRecordingUrl ? (
    <>
      <Stack direction="row" alignItems="center" flexShrink={0} sx={{ p: 2 }}>
        <Stack direction="row" spacing={1} flexGrow={1}>
          <Typography variant='subtitle2' sx={{ marginY: 'auto' }}>Play Recording</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" flexShrink={0} sx={{ px: 2 }}>
        <Stack direction="row" spacing={1} flexGrow={1}>
          <AudioPlayer urlRecording={selectRecordingUrl} />
        </Stack>
      </Stack>
    </>
  ) : null;

  const renderTranscriptRecording = (
    <>
      <Stack direction="row" alignItems="center" flexShrink={0} sx={{ pt: 2, px: 2 }}>
        <Stack direction="row" spacing={1} flexGrow={1}>
          <Typography variant='subtitle2' sx={{ marginY: 'auto' }}>Transcript</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" flexShrink={0} sx={{ px: 2 }}>
        <Stack direction="row" spacing={1} flexGrow={1}>
          <Typography variant='body2' sx={{ marginY: 'auto' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ullamcorper neque consectetur mi feugiat, ac maximus risus mattis.
            Donec ut enim vehicula eros porta facilisis quis ac velit. Etiam eu elit sit amet turpis commodo venenatis porta at
            odio. Cras metus eros, egestas id quam eget, ornare ultricies nulla. Aliquam erat volutpat. Curabitur ultricies volutpat pretium.
            Nunc suscipit augue eu orci facilisis, sit amet maximus sem accumsan. Cras in maximus nisi
          </Typography>
        </Stack>
      </Stack>
    </>
  );

  // VoiceMail Render 
  const renderHeadVoiceMail = (
    <Stack direction="row" alignItems="center" flexShrink={0} sx={{ height: 56, pl: 2, pr: 1 }}>
      {/* Contenedor izquierdo */}
      <Stack direction="row" alignItems="center" spacing={1}>
        <Chip
          variant="outlined"
          label={
            <span>
              <strong>DATE</strong> {moment(mail.dateCreated).format('LL')} {moment(mail.dateCreated).format('h:mm:ss a')}
            </span>
          }
        />
        <Chip
          variant="outlined"
          label={
            <span>
              <strong>From</strong> {mail.from}
            </span>
          }
        />
      </Stack>

      {/* Contenedor derecho */}
      <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 'auto' }}>
        <Chip
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Iconify icon="si:phone-fill" width="1.2rem" height="1.2rem" />
              <span style={{ marginLeft: '0.5rem' }}>Call</span>
            </span>
          }
          onClick={handleClick}
        />
        <Chip
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Iconify icon="material-symbols:delete-outline" width="1.2rem" height="1.2rem" />
              <span style={{ marginLeft: '0.5rem' }}>Delete Message</span>
            </span>
          }
          color="error" // Esto le da un color rojo al Chip
          onClick={handleClick}
        />
      </Stack>
    </Stack>
  );

  return (
    <>
      {
        renderLabel !== "voicemail" ? (
          <Stack
            flexGrow={1}
            sx={{
              width: 1,
              minWidth: 0,
              borderRadius: 1.5,
              bgcolor: 'background.default',
              overflowY: 'scroll',
            }}
          >
            {renderHead}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {/* {renderLabel !== "missed" ? (renderCallDetails) : (<></>)} */}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderLabel !== "missed" ? (renderRecordingCall) : (<></>)}
            {/* {renderTranscriptRecording} */}
          </Stack>
        ) : (
          <Stack
            flexGrow={1}
            sx={{
              width: 1,
              minWidth: 0,
              borderRadius: 1.5,
              bgcolor: 'background.default',
              overflowY: 'scroll',
            }}
          >
            {renderHeadVoiceMail}

            {/* <Divider sx={{ borderStyle: 'dashed' }} />

            {renderCallDetails} */}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderRecordingCall}
            {/* {renderTranscriptRecording} */}
          </Stack>
        )
      }
    </>
  );
}

CallDetails.propTypes = {
  mail: PropTypes.object,
  renderLabel: PropTypes.func,
  selectRecordingUrl: PropTypes.string
};

/*   const renderSubject = (
    <Stack spacing={2} direction="row" flexShrink={0} sx={{ p: 2 }}>
      <TextMaxLine variant="subtitle2" sx={{ flexGrow: 1 }}>
        Re: {mail.subject}
      </TextMaxLine>

      <Stack spacing={0.5}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <IconButton size="small">
            <Iconify width={18} icon="solar:reply-bold" />
          </IconButton>

          <IconButton size="small">
            <Iconify width={18} icon="solar:multiple-forward-left-broken" />
          </IconButton>

          <IconButton size="small">
            <Iconify width={18} icon="solar:forward-bold" />
          </IconButton>
        </Stack>

        <Typography variant="caption" noWrap sx={{ color: 'text.disabled' }}>
          {fDateTime(mail.createdAt)}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderSender = (
    <Stack
      flexShrink={0}
      direction="row"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(2, 2, 1, 2),
      }}
    >
      <Avatar alt={mail.from.name} src={`${mail.from.avatarUrl}`} sx={{ mr: 2 }}>
        {mail.from.name.charAt(0).toUpperCase()}
      </Avatar>

      <ListItemText
        primary={
          <>
            {mail.from.name}
            <Box component="span" sx={{ typography: 'body2', color: 'text.disabled' }}>
              {` <${mail.from.email}>`}
            </Box>
          </>
        }
        secondary={
          <>
            {`To: `}
            {mail.to.map((person) => (
              <Link key={person.email} sx={{ color: 'text.secondary' }}>
                {`${person.email}, `}
              </Link>
            ))}
          </>
        }
        secondaryTypographyProps={{
          mt: 0.5,
          noWrap: true,
          component: 'span',
          typography: 'caption',
        }}
      />
    </Stack>
  );

  const renderAttachments = (
    <Stack
      spacing={1}
      sx={{
        p: 1,
        borderRadius: 1,
        bgcolor: 'background.neutral',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ButtonBase
          onClick={showAttachments.onToggle}
          sx={{ color: 'text.secondary', typography: 'caption', borderRadius: 0.5 }}
        >
          <Iconify icon="eva:attach-2-fill" sx={{ mr: 0.5 }} />
          {mail.attachments.length} attachments
          <Iconify
            icon={
              showAttachments.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
            }
            width={16}
            sx={{ ml: 0.5 }}
          />
        </ButtonBase>

        <Button startIcon={<Iconify icon="eva:cloud-download-fill" />}>Download</Button>
      </Stack>

      <Collapse in={showAttachments.value} unmountOnExit timeout="auto">
        <Stack direction="row" flexWrap="wrap" spacing={1}>
          {mail.attachments.map((attachment) => (
            <Stack
              key={attachment.id}
              alignItems="center"
              justifyContent="center"
              sx={{
                width: 40,
                height: 40,
                flexShrink: 0,
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'background.neutral',
              }}
            >
              <FileThumbnail
                tooltip
                imageView
                file={attachment.preview}
                onDownload={() => console.info('DOWNLOAD')}
                sx={{ width: 24, height: 24 }}
              />
            </Stack>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );

  const renderContent = (
    <Box sx={{ py: 3, overflow: 'hidden', flexGrow: 1 }}>
      <Scrollbar>
        <Markdown
          children={mail.message}
          sx={{
            px: 2,
            '& p': {
              typography: 'body2',
            },
          }}
        />
      </Scrollbar>
    </Box>
  );

  const renderEditor = (
    <Stack
      spacing={2}
      sx={{
        p: (theme) => theme.spacing(0, 2, 2, 2),
      }}
    >
      <Editor simple id="reply-mail" />

      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <IconButton>
            <Iconify icon="solar:gallery-add-bold" />
          </IconButton>

          <IconButton>
            <Iconify icon="eva:attach-2-fill" />
          </IconButton>
        </Stack>

        <Button
          variant="contained"
          color="primary"
          endIcon={<Iconify icon="iconamoon:send-fill" />}
        >
          Send
        </Button>
      </Stack>
    </Stack>
  ); */