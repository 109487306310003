import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import { LandingEditView } from 'src/sections/landing/view';


export default function LandingEditPage() {

  return (
    <>
      <Helmet>
        <title> SalesRushAI | Edit Landing Page</title>
      </Helmet>

      <LandingEditView />
    </>
  )

}

LandingEditPage.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
};
