import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    subscriptions: [],

};

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions(state, action) {
        const { subscriptions } = action.payload;
        state.subscriptions = subscriptions;
    },
    clearSubscriptions(state) {
      state.subscriptions = initialState.subscriptions;
      state.actionType = initialState.actionType;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setSubscriptions,
  clearSubscriptions,
} = slice.actions;
