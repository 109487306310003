import axios from 'axios';


// ----------------------------------------------------------------------

const axiosInstanceExternal = axios.create({ baseURL: 'https://multimedia.salesrush.ai' });

axiosInstanceExternal.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstanceExternal;

// ----------------------------------------------------------------------

export const fetcherExternal = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstanceExternal.get(url, { ...config });

  return res.data;
};
