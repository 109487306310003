const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    address: "",
    apartment: "",
    firstname : "",
    lastname : "",
    firstTimeHomeBuyer : "",
    planToBuy : "",
    useProperty : "",
    mortgageAmount : "",
    creditScore : "",
    militaryService : "",
    type: 'New Home',
    status: 'lead',
    role: 'user',
    typeProperty: "",
    ip: '',
    enrolled: '',
    by_phone: true,
    phone: '',
    email: '',
    clientID:'',
    isValidated: false,
    validationCode: ""
}

const newHomeSlice = createSlice({
    name: 'newhome',
    initialState,
    reducers: {
        reset:() => initialState,
        setNewHomeValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        }
    }
});

export const { setNewHomeValue, reset } = newHomeSlice.actions;

export default newHomeSlice.reducer;
