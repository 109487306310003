import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SALESRUSHAPI } from 'src/config-global';

const salesRushApiBaseQuery = createApi({
    reducerPath: 'salesrushApi',
    baseQuery: fetchBaseQuery({
        baseUrl: SALESRUSHAPI.api_url,
    }),
    endpoints: () => ({}),
});

export default salesRushApiBaseQuery;
