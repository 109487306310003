import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Iconify from "src/components/iconify";

const PhoneNumberActions = () => {


  const [value, setValue] = useState(3);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          maxWidth: 500,
          width: '100%',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'space-evenly',
          p: 0,
          paddingBottom: 3,
        }}
      >
        <BottomNavigationAction
          label="Recents"
          sx={{
            minWidth: 'auto',
            padding: 0,
            margin: 0,
          }}
          icon={<Iconify icon="fa:history" width=".85rem" height=".85rem" />}
        />
        <BottomNavigationAction
          label="CRM"
          sx={{
            minWidth: 'auto',
            padding: 0,
            margin: 0,
          }}
          icon={<Iconify icon="ph:user-fill" width=".9rem" height=".9rem" />}
        />
        <BottomNavigationAction
          label="Users"
          sx={{
            minWidth: 'auto',
            padding: 0,
            margin: 0,
          }}
          icon={<Iconify icon="gridicons:multiple-users" width="1rem" height="1rem" />}
        />
        <BottomNavigationAction
          label="Keypad"
          sx={{
            minWidth: 'auto',
            padding: 0,
            margin: 0,
          }}
          icon={<Iconify icon="eva:keypad-fill" width="1rem" height="1rem" />}
        />
        <BottomNavigationAction
          label="Voicemail"
          sx={{
            minWidth: 'auto',
            padding: 0,
            margin: 0,
          }}
          icon={<Iconify icon="ph:voicemail-bold" width="1rem" height="1rem" />}
        />
      </BottomNavigation>
    </Box>

  );
}

export default PhoneNumberActions;
